import React, { FC } from "react";

import { tw } from "../../tailwindcss-classnames";

import { FullscreenWrapperBGColors } from "./fullscreen-flex-wrapper";

export const HeaderWrapper: FC<{
  bg: FullscreenWrapperBGColors;
}> = (props) => {
  return (
    <div
      className={tw(
        "bg-gold",
        "text-white",
        "bg-no-repeat",
        "bg-bottom",
        // todo: handle all colours
        { "bg-shapeGoldWhite": props.bg === "bg-white" },
        { "bg-shapeGoldEcru": props.bg === "bg-ecru" },
        { "bg-shapeGoldSoftGold": props.bg === "bg-soft-gold" },
        "bg-fullWidth",
        "bg-bottom",
        "pb-48",
        "container:pb-24",
        "container:mb-24",
        "container:bg-none"
      )}
    >
      <div
        className={tw("max-w-screen-container-large", "mx-auto", "space-y-12")}
      >
        {props.children}
      </div>
    </div>
  );
};
