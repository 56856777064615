import React, { FC } from "react";
import tw, { TArg as TArgument } from "../../tailwindcss-classnames";
import { Button } from "../button/button";
import { Errors } from "../form/errors";
import { useStandardForm } from "../form/use-standard-form";
import { B1 } from "../type/Typography";
import { useFocusFirstChildInput } from "lib/useFocusFirstChildInput";
import { TextLink } from "components/link/text-link";

export type SignInFormData = {
  email: string;
  password: string;
};

const defaultValues: SignInFormData = {
  email: "",
  password: "",
};
const inputs = ["email", "password"] as const;

export const SignInPageComponent: FC<{
  isLoading: boolean;
  onSubmit: (data: SignInFormData) => Promise<void>;
  error: any;
}> = ({ onSubmit, error }) => {
  const reference = useFocusFirstChildInput();
  const { form, getInput, submitButton } = useStandardForm(
    null,
    defaultValues,
    "login"
  );

  return (
    <div
      className={tw("max-w-screen-container-large", "mx-auto", "p-16")}
      ref={reference}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} className={tw("space-y-16")}>
        {inputs.map((it) => getInput(it, { key: it }))}
        <Errors errors={error} />

        <TextLink
          href={"/auth/forgotpassword"}
          className="flex justify-end mt-0"
        >
          <span>Forgot Password?</span>
        </TextLink>
        <div className={tw("space-y-16")}>
          {submitButton("Log In")}
          <hr className={tw("border-unassumingGray" as TArgument, "block")} />
          <B1>Don't have an account yet?</B1>
          <Button href={"/signup"} color="sand">
            Sign Up
          </Button>
        </div>
      </form>
    </div>
  );
};
