import Link from "next/link";
import { FC, HTMLAttributes } from "react";
import { tw } from "../../tailwindcss-classnames";
import { c } from "lib/c";

type TextLinkProps = {
  href: string;
  target?: string;
  className?: string;
} & HTMLAttributes<HTMLAnchorElement>;

export const TextLink: FC<TextLinkProps> = ({ href, children, ...rest }) => {
  if (href.startsWith("http")) {
    return <a {...rest}>{children}</a>;
  }
  return (
    <Link href={href} passHref={true}>
      <a {...rest}>{children}</a>
    </Link>
  );
};

export const UnderlineLink: FC<TextLinkProps> = (props) => (
  <TextLink {...props} className={c(tw("underline", "text-gold"))}>
    {props.children}
  </TextLink>
);
