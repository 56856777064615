import { RefObject, useEffect, useRef } from "react";

export function useFocusFirstChildInput<
  T extends HTMLElement = HTMLDivElement
>(): RefObject<T> {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const firstEle = ref.current.querySelector<
        HTMLInputElement | HTMLSelectElement
      >(
        "input[type=text],input[type=number],input[type=email],input[type=password],select"
      );

      firstEle.focus();
    }
  }, [typeof window]);

  return ref;
}
