import { useApolloClient } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";

import {
  SignInFormData,
  SignInPageComponent,
} from "../components/auth/sign-in";
import { Footer } from "../components/display/Footer";
import { FullscreenFlexWrapper } from "../components/display/fullscreen-flex-wrapper";
import { HeadNoMenu } from "../components/display/HeadNoMenu";
import { useSigninMutation } from "../graphql/generated";
import { tw } from "../tailwindcss-classnames";

import { useAuth } from "lib/auth";

function Login() {
  const { setToken } = useAuth();
  const router = useRouter();
  const client = useApolloClient();
  const [signIn, { loading, error }] = useSigninMutation();
  const [errorState, setError] = useState<null | undefined | Error | Error[]>();

  const handleSubmit = useCallback<SubmitHandler<SignInFormData>>(
    async (formData, e) => {
      e.preventDefault();
      try {
        await client.resetStore();
        const { data, errors } = await signIn({
          variables: {
            email: formData.email,
            password: formData.password,
          },
        });
        if (errors && errors.length > 0) {
          throw errors;
        }
        setToken(data.signIn.token);
        if (typeof (window as any).mixpanel !== "undefined") {
          const mixpanel = (window as any).mixpanel;
          mixpanel.identify(data.signIn.user.email);
        }
        window.location.href = `/users/${data.signIn.user.id}/signedin`;
      } catch (error) {
        if (error.message === "Otp Verify") {
          const otpError = error.graphQLErrors.find(
            (i) => i.message === "Otp Verify"
          );
          setToken(otpError.extensions.exception.token);
          if (typeof (window as any).mixpanel !== "undefined") {
            const mixpanel = (window as any).mixpanel;
            mixpanel.identify(otpError.extensions.exception.userDetails.email);
          }
          window.location.href = "/otp?fL=t";
        } else {
          setError(error);
        }
      }
    },
    []
  );

  useEffect(() => {
    router.prefetch(`/users/[:userId]/signedin`);
    router.prefetch(`/users/[:userId]/home`);
  }, []);

  return (
    <FullscreenFlexWrapper title="Sign In" bg="bg-white">
      <div className={tw("w-full", "flex-1", "flex-shrink-0")}>
        <HeadNoMenu heading={"Log In"} bg="bg-white" className="px-12" />
        <SignInPageComponent
          onSubmit={handleSubmit}
          error={errorState}
          isLoading={loading}
        />
        {/* <Errors errors={error} /> */}
      </div>
      <Footer className={tw("w-full", "bg-ecru")} />
    </FullscreenFlexWrapper>
  );
}

export default Login;
