import React, { FC, ReactNode } from "react";

import { tw } from "../../tailwindcss-classnames";
import { B1, H1 } from "../type/Typography";

import { FullscreenWrapperBGColors } from "./fullscreen-flex-wrapper";
import { HeaderWrapper } from "./HeaderWrapper";

export const HeadNoMenu: FC<{
  bg: FullscreenWrapperBGColors;
  heading: string | ReactNode;
  subheading?: string | ReactNode;
  showSubheadingOnDesktop?: boolean;
  className?: string;
}> = ({
  bg,
  heading,
  subheading,
  showSubheadingOnDesktop,
  className,
  children,
}) => {
  return (
    <HeaderWrapper bg={bg}>
      <div className={className}>
        {children ? (
          children
        ) : (
          <p className="text-l container:text-xl font-bold text-white">
            GoldenEggs
          </p>
        )}

        <H1 className={tw("mx-auto", "w-4/6", "pt-12")}>{heading}</H1>

        {subheading && (
          <B1
            className={tw({
              hidden: !showSubheadingOnDesktop,
              "container:block": true,
              "text-center": true,
              "pb-16": true,
            })}
          >
            {subheading}
          </B1>
        )}
      </div>
    </HeaderWrapper>
  );
};
